
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CompanyInfo extends Vue {
  @Prop({ default: '' }) blockName!: string;
  @Prop({ default: 0 }) annualRevenueLevel!: number;
  @Prop({ default: 0 }) pSalesValue!: number;
  @Prop({ default: () => ({}) }) companyDetailsObject!: Record<string, string>;

  get isUsersCompanyNotCalculated(): boolean {
    return Object.keys(this.companyDetailsObject).length === 0;
  }
  get sales(): string {
    const actualValue = this.annualRevenueLevel || Number(this.companyDetailsObject.revenue);
    const formatedValue = this.universalFormatter(actualValue);
    return Number.isNaN(Number(actualValue)) ? '0' : `${formatedValue}`;
  }
  get valuation(): string {
    const actualValue = this.annualRevenueLevel * this.pSalesValue || Number(this.companyDetailsObject.businessValue);
    const formatedValue = this.universalFormatter(actualValue);
    return Number.isNaN(Number(actualValue)) ? '0' : `${formatedValue}`;
  }

  valuesFormatter(value: string): string {
    const formatedValue = this.universalFormatter(Number(value));
    return Number.isNaN(Number(value)) ? 'Connect applications' : `${formatedValue}`;
  }
  percentFormatter(value: string): string {
    const formatedValue = this.universalFormatter(Number(value));
    return Number.isNaN(Number(value)) ? '' : `${formatedValue} %`;
  }
  universalFormatter(value: number): string {
    const formatter = new Intl.NumberFormat('en-GB', {
      notation: 'compact',
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  }
  connectAplications(): void {
    this.$emit('connectAplications');
  }
}
