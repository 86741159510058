
import { getBestCompanyOptions, getPeerCompanyDetails } from '@/api/common';
import { getIndustriesList } from '@/api/industriesAndLocation';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseSelect from '@/components/baseComponents/BaseSelect.vue';
import BaseSelectPeerCompanies from '@/components/baseComponents/BaseSelectPeerCompanies.vue';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';
import CompanyInfo from '@/components/company/CompanyInfo.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IIndustries } from '../../models';

@Component({
  components: {
    TitleDescriptionUniversal,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseSelectPeerCompanies,
    CompanyInfo,
    BaseCirclePreloader,
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
  },
})
export default class BusinessValuePage extends Vue {
  public isAuthenticated!: boolean;

  optionsIndustries = [];
  bestCompanyOptions = {
    isTheBestCompany: '',
    companiesList: [],
  };
  companyDetailsObject: Record<string, string> = {};
  isPreloaderShow = false;
  isPreloaderCalculateShow = false;
  isShowUserCompanyInfo = false;
  isHidePeerCompanyInfo = false;
  errors = {
    annualRevenueLevel: false,
    industry: false,
  };

  get industryInfo(): any {
    const industry = this.optionsIndustries.find((option: IIndustries) => option.id === this.industry);
    return industry || { priceToSalesRatio: 0, peerCompanies: 0 };
  }
  get isShowPeerCompanyInfo(): boolean {
    return Object.keys(this.companyDetailsObject).length !== 0;
  }
  get resultValue(): string {
    const calculatedValuation = Number(this.annualRevenueLevel) * this.industryInfo?.priceToSalesRatio;
    return new Intl.NumberFormat('en-GB').format(Math.trunc(calculatedValuation));
  }
  get selectedIndustry(): string {
    return (this.optionsIndustries as any).find((option: IIndustries) => option.id === this.industry)?.name;
  }
  get annualRevenueLevel(): string {
    return this.$store.state.user.annualRevenueLevel;
  }
  set annualRevenueLevel(value: string) {
    this.$store.commit('user/setAnnualRevenueLevel', value);
  }
  get industry(): string {
    return this.$store.state.user.industryForBusinessValuation;
  }
  set industry(value: string) {
    this.$store.commit('user/setIndustryForBusinessValuation', value);
  }
  get peerCompany(): string {
    return this.$store.state.user.peerCompany;
  }
  set peerCompany(value: string) {
    this.$store.commit('user/setPeerCompany', value);
  }
  get correctFields(): boolean {
    return Number(this.annualRevenueLevel) > 0 && Boolean(this.industry);
  }
  get nextButtonText(): string {
    return this.correctFields ? 'Get more accurate assessment' : 'All fields are required';
  }

  checkField(field: string): void {
    switch (field) {
      case 'annualRevenueLevel':
        this.errors.annualRevenueLevel = Number(this.annualRevenueLevel) === 0;
        break;
      case 'industry':
        this.errors.industry = Boolean(!this.industry);
        break;
      default:
        break;
    }
  }
  async getAndRefreshDetailsCompany(id: string): Promise<void> {
    try {
      this.companyDetailsObject = ((await getPeerCompanyDetails(id)) as any).data;
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }
  async connectAplications(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      this.validateFields();
      await this.registerIfNeeded();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderShow = false;
    }
  }
  validateFields(): void {
    if (Number(this.annualRevenueLevel) === 0) {
      this.$nextTick(() => {
        this.$notify({
          group: 'main-notification',
          type: 'info-warning',
          text: 'Annual revenue can not be zero',
        });
      });
    }
  }
  async registerIfNeeded(): Promise<void> {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'business-valuation' });
    } else {
      this.$router.push({ name: 'RegistrationPage' });
    }
  }
  async assignmentIndustries(): Promise<void> {
    try {
      this.isPreloaderCalculateShow = true;
      const optionsIndustriesArr = ((await getIndustriesList()) as any).data;
      this.optionsIndustries = this.industriyByLang(optionsIndustriesArr, 'en-US');
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderCalculateShow = false;
    }
  }
  industriyByLang(industriesArr: any, lang: string): never[] {
    return industriesArr.map((item: any) => {
      const name = JSON.parse(item.name)[lang];
      return { ...item, name };
    });
  }
  async assignmentPeerCompanies(): Promise<void> {
    try {
      this.isPreloaderCalculateShow = true;
      this.isShowUserCompanyInfo = false;
      this.bestCompanyOptions = (await getBestCompanyOptions(this.annualRevenueLevel, this.industry)) as any;
      this.$store.commit('user/setPeerCompany', this.bestCompanyOptions.isTheBestCompany);
      this.getAndRefreshDetailsCompany(this.bestCompanyOptions.isTheBestCompany);
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderCalculateShow = false;
      this.isShowUserCompanyInfo = true;
      this.isHidePeerCompanyInfo = false;
    }
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  created(): void {
    this.assignmentIndustries();
    if (this.correctFields) this.assignmentPeerCompanies();
  }
  mounted(): void {
    this.validateFields();
  }

  @Watch('annualRevenueLevel')
  hideGreenBlock(): void {
    this.isShowUserCompanyInfo = false;
    this.isHidePeerCompanyInfo = true;
  }
  @Watch('industry')
  recalculatePeerCompany(): void {
    if (this.correctFields) this.assignmentPeerCompanies();
  }
}
