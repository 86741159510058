var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.checkField),expression:"checkField"}],staticClass:"base-select-peer-companies",attrs:{"tabindex":"0"},on:{"click":_vm.showOptions,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.showOptions.apply(null, arguments)}}},[(_vm.label)?_c('p',{staticClass:"base-select-peer-companies__label caption1"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:['base-select-peer-companies__selected', { disabled: _vm.disabled }]},[_c('p',{class:[
        'body2',
        'font-semibold',
        { 'selected-option': _vm.selectedOption || _vm.parentOption !== _vm.defaultOptionText },
      ]},[_vm._v(" "+_vm._s(_vm.selectedOption || _vm.parentOption)+" ")]),_c('icon-breadcrumb',{attrs:{"fill":"#7F7F7F"}})],1),_c('transition',{attrs:{"name":"options"}},[(_vm.isShowOptions)?_c('div',{ref:"options",staticClass:"base-select-peer-companies__options",style:(_vm.positionBlock)},_vm._l((_vm.options),function(option,index){return _c('p',{key:index,class:[
          'body2',
          'font-semibold',
          { 'blue-selected-option': option.name === _vm.selectedOption || option.name === _vm.parentOption },
        ],attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectOption(option)},"click":function($event){return _vm.selectOption(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }