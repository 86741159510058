
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue';
import vClickOutside from 'v-click-outside';
import { IIndustries } from '../../models';

@Component({
  components: {
    IconBreadcrumb,
  },
})
export default class BaseSelectPeerCompanies extends Vue {
  $refs!: {
    options: HTMLElement;
  };

  @Prop({ default: {} }) options!: any;
  @Prop({ default: 'Select' }) defaultOptionText!: string;
  @Prop({ default: '' }) companyDescription!: string;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) value!: any;
  @Prop({ default: false }) disabled!: boolean;

  isShowOptions = false;
  isInputClicked = false;
  positionBlock = '';
  selectedOption = '';

  get parentOption(): string {
    return this.options.find((option: IIndustries) => option.id === this.value)?.name || this.defaultOptionText;
  }
  get optionsPosition(): number {
    return this.isShowOptions ? this.$refs.options.getBoundingClientRect().bottom : 0;
  }
  get optionsSize(): any {
    return this.isShowOptions ? this.$refs.options.offsetHeight : 0;
  }

  showOptions(): void {
    this.isInputClicked = true;
    this.isShowOptions = !this.isShowOptions;
    this.$nextTick(() => {
      this.changePosition();
    });
  }
  changePosition(): void {
    this.positionBlock = window.innerHeight - this.optionsPosition < this.optionsSize
      ? 'bottom: 84px; top: unset;'
      : 'top: 84px; bottom: unset;';
  }
  closeOptions(): void {
    this.isShowOptions = false;
  }
  selectOption(option: any): void {
    this.selectedOption = option.name;
    this.$emit('input', option.id);
    this.$emit('getAndRefreshDetailsCompany', option.id);
    this.$nextTick(() => {
      this.changePosition();
    });
  }
  checkField(): void {
    this.closeOptions();
    if (this.isInputClicked) this.$emit('checkField');
  }

  created(): void {
    Vue.use(vClickOutside);
  }
}
